<template>
  <div class="app-container flex flex-v">
    <div class="searchBar bg-white" style="border: 1px solid white">
      <el-form ref="searchForm" :model="searchForm" label-width="80px" size="mini">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-form-item label="任务">
              <el-input v-model="searchForm.serviceCode" clearable placeholder="任务名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item>
              <el-button
                  icon="el-icon-search"
                  type="primary"
                  size="mini"
                  @click="searchFormSubmit"
              >查 询
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="bg-white main-content-wrapper margin-10 flex flex-1 flex-v">
      <el-table
        stripe
        class="flex-1"
        style="margin-top: 15px;"
        height="400px"
        v-loading="tableLoading"
        size="mini"
        :data="tableList"
        :header-cell-style="{ background:'#f2f2f2' }"
      >
        <el-table-column label="任务名称" prop="jobNum" align="center"></el-table-column>
        <el-table-column show-overflow-tooltip label="周期公式" prop="cron" align="center"></el-table-column>
        <el-table-column label="状态" prop="status" align="center">
          <template slot-scope="scope">
            <span v-show="scope.row.status === '0'">已启用</span>
            <span v-show="scope.row.status === '1'">已停止</span>
          </template>
        </el-table-column>
        <el-table-column label="任务描述" prop="jobDiscription" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column show-overflow-tooltip label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="text" @click="editRecord(scope.row)">编辑</el-button>
            <el-button v-show="scope.row.status === '1'" size="mini" type="text" @click="handleStart(scope.row)">启用</el-button>
            <el-button v-show="scope.row.status === '0'" size="mini" type="text" @click="handleStop(scope.row)">停止</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination pagi-sty">
        <!-- 分页-->
        <Pagination
          :page.sync="tablePage.currentPage"
          :total="tablePage.total"
          :limit.sync="tablePage.pageSize"
          @pagination="paginationChange"
        />
      </div>
    </div>
    <!-- 设置周期公式 -->
    <div class="dialog-content">
      <el-dialog
        :title="diaTitle"
        :visible.sync="dialogVisible"
        width="50%"
        >
        <div>
          <el-row>
            <el-col :span="6" :offset="9">
              <cron-input v-model="cron" @change="change" @reset="reset"/>
            </el-col>
          </el-row>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import tables from '@/mixins/tables'
import cronService from '@/api/cron-task'
import CronInput from 'vue-cron-generator/src/components/cron-input'
import { DEFAULT_CRON_EXPRESSION } from 'vue-cron-generator/src/constant/filed'

export default {
  // 定时任务设置
  name: 'cron-jobs-setting',
  components: { Pagination, CronInput },
  mixins: [tables],
  data() {
    return {
      searchForm: {
        serviceCode: ''
      },
      diaTitle: '设置周期公式',
      cron: DEFAULT_CRON_EXPRESSION,
      dialogVisible: false,
      currentItem: {}
    }
  },
  mounted() {
    console.log('DEFAULT_CRON_EXPRESSION', DEFAULT_CRON_EXPRESSION)
  },
  methods: {
    /**
     * ！！！必须方法，获取 list 接口
     *
     * @params {Object} data 请求数据：包含 searchForm 和 tablePage 属性
     * @return {Promise} Promise 获取 list 接口的异步方法
     */
    async getListApi(data) {
      return new Promise((resolve, reject) => {
        cronService.selectCronList(data).then(data => {
          resolve(data.data)
        }).catch(e => {
          reject(e)
        })
      })
    },
    change(cron) {
      this.cron = cron
    },
    reset(cron) {
      this.cron = DEFAULT_CRON_EXPRESSION
    },
    // 修改周期公式
    editRecord(row) {
      this.currentItem = row
      this.diaTitle = row.jobNum || '设置周期公式'
      this.cron = row.cron || DEFAULT_CRON_EXPRESSION
      this.dialogVisible = true
    },
    // 周期公式保存
    handleUpdate() {
      if(!this.cron) {
        this.$message.error('请先设置周期公式')
        return
      }
      let postData = Object.assign(this.currentItem, { cron: this.cron })
      cronService.updateCronItem(postData).then(res => {
        this.singleData = res
        if(res.success){
          this.$message.success('操作成功')
          this.dialogVisible = false
          this.searchFormSubmit()
        }else {
          this.$message.error(res.message)
        }

      }).catch(e => {
        console.log(e)
      })
    },
    // 启动定时任务
    handleStart(row) {
      cronService.startCron(row).then(res => {
        this.$message.success('操作成功')
        this.searchFormSubmit()
      }).catch(e => {
        console.log(e)
      })
    },
    // 停止定时任务
    handleStop(row) {
      cronService.stopCron(row).then(res => {
        this.$message.success('操作成功')
        this.searchFormSubmit()
      }).catch(e => {
        console.log(e)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.app-container {
  background-color: #f6f6f6;
  height: 100%;
}

.icon {
  font-size: 16px;
  line-height: 0 !important;
}

::v-deep .el-pagination__total {
  float: right;
}

::v-deep .el-tooltip__popper {
  line-height: 0.5;
}

.el-form {
  background: #FFFFFF;
  padding: 20px 20px 0 20px;
  margin: 10px;
  .el-col {
    padding-left: 20px;
    padding-right: 20px;
    ::v-deep .el-form-item__label {
      font-size: 12px;
    }
    .el-input {
      width: 240px;
    }
  }
}
::v-deep .el-table__header-wrapper .el-checkbox {
  right: 4px;
}
.pagi-sty {
  width: 100%;
  padding-top: 20px;
  text-align: right;
}
.dialog-content {
  ::v-deep .el-dialog__body {
    min-height: 420px;
  }
}
</style>
