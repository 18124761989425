import { postRequest, putRequest } from "@/utils/request_utils"

export default {
  // 查询定时任务列表
  selectCronList: (data)=> {
    return postRequest(`/api-base/quartz/cron/selectCron`,{data});
  },
  // 修改定时任务周期
  updateCronItem: (data)=> {
    return putRequest(`/api-base/quartz/cron/updateCron`,{data});
  },
  // 开始定时任务
  startCron: (data)=> {
    return postRequest(`/api-base/quartz/cron/startCron`,{data});
  },
  // 停止定时任务
  stopCron: (data)=> {
    return postRequest(`/api-base/quartz/cron/stopCron`,{data});
  },
}

